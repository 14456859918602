<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li v-for="(breadcrumb, index) in titleStack" :key="index">
        <router-link :to="breadcrumb.link">{{ breadcrumb.name }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  padding: 1rem;
  margin: 0;
}
</style>
